import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-item-modal',
  templateUrl: './item-modal.component.html',
  styleUrls: ['./item-modal.component.scss']
})
export class ItemModalComponent implements OnInit {
  tableLabels = [];

  constructor(
    private dialogRef: MatDialogRef<ItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    const tableObj = this.data.content.filter((item) => {
      return !this.checkIfString(item);
    });
    if (tableObj.length) {
      this.tableLabels = Object.keys(tableObj[0].table);
    }
  }

  checkIfString(str: string) {
    return typeof str === 'string';
  }

}

<div id="main">
  <div>
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput formControlName="searchTerm">
      </mat-form-field>
      <mat-checkbox formControlName="common">Common</mat-checkbox>
      <mat-checkbox formControlName="uncommon">Uncommon</mat-checkbox>
      <mat-checkbox formControlName="rare">Rare</mat-checkbox>
      <mat-checkbox formControlName="veryRare">Very Rare</mat-checkbox>
      <mat-checkbox formControlName="legendary">Legendary</mat-checkbox>
    </form>
  </div>
  <table mat-table [dataSource]="items" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="rarity">
      <th mat-header-cell *matHeaderCellDef> Rarity </th>
      <td mat-cell *matCellDef="let element"> {{element.content ? element.content[0] : ''}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef> Cost </th>
      <td mat-cell *matCellDef="let element"> {{element.content ? (element.content[0] | determineCost) : ''}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef> Info </th>
      <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="openModal(element)">Info</button> </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<h1 mat-dialog-title>{{data.name}}</h1>
<div mat-dialog-content>
    <div *ngFor="let item of data.content">
        <span *ngIf="!item.table">
            {{item}}
        </span>
        <div *ngIf="item.table">
            <table>
                <thead>
                    <tr>
                        <th *ngFor="let key of tableLabels">{{key}}</th>
                    </tr>
                    <tr *ngFor="let row of item.table[tableLabels[0]]; let i = index">
                        <td *ngFor="let key of tableLabels">
                            {{item.table[key][i]}}
                        </td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import * as items from '../assets/items.json';
import { ItemModalComponent } from './item-modal/item-modal.component';
import { takeUntil } from 'rxjs/operators';

const sortBy = (key) => {
  return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _items: any[] = [];
  items: any[] = [];
  displayedColumns = ['name', 'rarity', 'cost', 'info'];
  form = this.fb.group({
    searchTerm: [''],
    common: [true],
    uncommon: [true],
    rare: [false],
    veryRare: [false],
    legendary: [false]
  });

  private destroy$ = new Subject();

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const keys = Object.keys(items['Magic Items']);
    const finalArray = [];
    keys.forEach((key: string) => {
      const item = items['Magic Items'][key];
      finalArray.push({
        ...item,
        name: key
      });
    });
    finalArray.splice(0, 1);
    this._items = finalArray.sort(sortBy('name'));
    this.filterItems();

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      console.log(val);
      this.filterItems();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  openModal(item) {
    this.dialog.open(ItemModalComponent, {data: item});
  }

  private filterItems() {
    let finalArray = [];
    const formValue = this.form.value;
    if (formValue.searchTerm) {
      this.items = this._items.filter((item) => {
        return item.name.toLowerCase().includes(formValue.searchTerm.toLowerCase());
      }).sort(sortBy('name'));
    } else {
      if (formValue.common) {
        const commonItems = this._items.filter((item) => {
          return item.content && item.content[0] && typeof item.content[0] === 'string' && item.content[0].includes('common') && !item.content[0].includes('uncommon');
        });
        finalArray = finalArray.concat(commonItems);
      }
      if (formValue.uncommon) {
        const uncommonItems = this._items.filter((item) => {
          return item.content && item.content[0] && typeof item.content[0] === 'string' && item.content[0].includes('uncommon');
        });
        finalArray = finalArray.concat(uncommonItems);
      }
      if (formValue.rare) {
        const rareItems = this._items.filter((item) => {
          return item.content && item.content[0] && typeof item.content[0] === 'string' && item.content[0].includes('rare') && !item.content[0].includes('very rare');
        });
        finalArray = finalArray.concat(rareItems);
      }
      if (formValue.veryRare) {
        const veryRareItems = this._items.filter((item) => {
          return item.content && item.content[0] && typeof item.content[0] === 'string' && item.content[0].includes('very rare');
        });
        finalArray = finalArray.concat(veryRareItems);
      }
      if (formValue.legendary) {
        const legendaryItems = this._items.filter((item) => {
          return item.content && item.content[0] && typeof item.content[0] === 'string' && item.content[0].includes('legendary');
        });
        finalArray = finalArray.concat(legendaryItems);
      }
      if (formValue.common || formValue.uncommon || formValue.rare || formValue.veryRare || formValue.legendary) {
        const variesItems = this._items.filter((item) => {
          return item.content && item.content[0] && typeof item.content[0] === 'string' && item.content[0].includes('varies');
        });
        finalArray = finalArray.concat(variesItems);
      }
      finalArray.sort(sortBy('name'));
      this.items = finalArray.length ? finalArray : JSON.parse(JSON.stringify(this._items));
    }
  }
}

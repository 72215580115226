import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'determineCost'
})
export class DetermineCostPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]) {
    if (value.includes('uncommon')) {
      return '500';
    } else if (value.includes('common')) {
      return '100';
    }  else if (value.includes('very rare')) {
      return '50,000';
    } else if (value.includes('rare')) {
      return '5,000';
    } else if (value.includes('legendary')) {
      return 'Not For Sale';
    } else {
      return 'Ask DM';
    }
  }

}
